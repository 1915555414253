import React from 'react';
import {
    Routes,
    Route,
    useNavigate,
    useLocation,
    useSearchParams,
} from "react-router";

import { useUser } from '../../context/user';

import { Base } from './base';
import { Main } from './main';
import { Login } from './login';
import { Register } from './register';

export const PATH = {
    AUTH: {
        INDEX: 'auth',
        LOGIN: 'login',
        REGISTER: 'create'
    }
}

export const Router = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { user, login, create } = useUser();

    const handleLogin = async (email, password) => {
        const result = await login(email, password)
        return result
    }

    const handleRegister = async (email, password) => {
        const result = await create(email, password)
        return result
    }

    const handleNavigate = (path) => {
        const from = searchParams.get('from') || '/'
        navigate(`${path}?from=${from}`)
    }

    return (
        <>
        <Routes>
            <Route element={<Base />}>
                <Route index element={<Main
                    onLogin={ () => handleNavigate(PATH.AUTH.LOGIN) }
                    onRegister={ () => handleNavigate(PATH.AUTH.REGISTER) }
                />}/>
                <Route path={ PATH.AUTH.LOGIN } element={<Login
                    onLogin={ handleLogin }
                    onBack={ () => handleNavigate(`/${PATH.AUTH.INDEX}`) }
                />} />
                <Route path={ PATH.AUTH.REGISTER } element={<Register
                    onRegister={ handleRegister }
                    onBack={ () => handleNavigate(`/${PATH.AUTH.INDEX}`) }
                />} />
            </Route>
        </Routes>
        </>
    )
}
