export const FocusEvent = ({
    emit: pos => window.dispatchEvent(new CustomEvent('focus', { detail: pos })),
    subscribe: (fn) => window.addEventListener('focus', fn),
    unsuscribe: (fn) => window.removeEventListener('focus', fn)
})

export const SelectModeEvent = ({
    emit: mode => window.dispatchEvent(new CustomEvent('selectMode', { detail: mode })),
    subscribe: (fn) => window.addEventListener('selectMode', fn),
    unsuscribe: (fn) => window.removeEventListener('selectMode', fn)
})

export const SelectColorEvent = ({
    emit: color => window.dispatchEvent(new CustomEvent('selectColor', { detail: color })),
    subscribe: (fn) => window.addEventListener('selectColor', fn),
    unsuscribe: (fn) => window.removeEventListener('selectColor', fn)
})

export const SetColorEvent = ({
    emit: color => window.dispatchEvent(new CustomEvent('setColor', { detail: color })),
    subscribe: (fn) => window.addEventListener('setColor', fn),
    unsuscribe: (fn) => window.removeEventListener('setColor', fn)
})

export const SetMapPinEvent = ({
    emit: pos => window.dispatchEvent(new CustomEvent('mappin')),
    subscribe: (fn) => window.addEventListener('mappin', fn),
    unsuscribe: (fn) => window.removeEventListener('mappin', fn)
})

export const ZoomEvent = ({
    emit: dir => window.dispatchEvent(new CustomEvent('zoom', { detail: dir })),
    subscribe: (fn) => window.addEventListener('zoom', fn),
    unsuscribe: (fn) => window.removeEventListener('zoom', fn)
})

export const UnitVisibilityEvent = ({
    emit: units => window.dispatchEvent(new CustomEvent('unitsVisibility', { detail: units })),
    subscribe: (fn) => window.addEventListener('unitsVisibility', fn),
    unsuscribe: (fn) => window.removeEventListener('unitsVisibility', fn)
})

export const PathsVisibilityEvent = ({
    emit: visible => window.dispatchEvent(new CustomEvent('pathsVisibility', { detail: visible })),
    subscribe: (fn) => window.addEventListener('pathsVisibility', fn),
    unsuscribe: (fn) => window.removeEventListener('pathsVisibility', fn)
})
