import React from 'react'
import styled from 'styled-components'

export const ClickableIcon = styled.div`
    color: black;
    cursor: pointer;
    &:hover {
    color: red;
}
`

export const SelectableIcon = styled.button`
    font-size: 1.2em;
    width: 2em;
    height: 2em;
    cursor: pointer;
    opacity: .5;

    ${ props => props.active && `
    opacity: 1;
    `}
`

const StyledIcon = styled.i`
    ${ props => props.onClick && `
        color: ${ props.theme.colors.main };
        cursor: pointer;
        &:hover {
            color: ${ props.theme.colors.mainDark };
        }
    ` }
`
export const Icon = ({ icon, ...props }) => <StyledIcon {...props} className={ `fa-solid fa-${ icon }` }/>

export const DeleteIcon = ({ onClick }) => <ClickableIcon onClick={ onClick }><i className="fa-regular fa-trash-can"></i></ClickableIcon>

export const CloseIcon = () => <i className="fa-solid fa-circle-xmark"></i>

export const SelectIcon = () => <i className="fa-solid fa-arrow-pointer"></i>

export const DrawIcon = () => <i className="fa-solid fa-pencil"></i>

export const HierarchyIcon = () => <i className="fa-solid fa-sitemap"></i>

export const CameraIcon = () => <i className="fa-solid fa-camera"></i>

export const ExportIcon = () => <i className="fa-solid fa-file-arrow-down"></i>

export const ExitIcon = () => <i className="fa-solid fa-right-from-bracket"></i>

export const PinIcon = () => <i className="fa-solid fa-location-dot"></i>

export const BugIcon = () => <i className="fa-solid fa-bug"></i>

export const PlusIcon = ({ style={} }) => <i style={ style } className="fa-solid fa-plus"></i>

export const MinusIcon = () => <i className="fa-solid fa-minus"></i>

export const StarIcon = ({ style={} }) => <i style={ style } className="fa-solid fa-star"></i>

