import React from 'react'
import styled from 'styled-components'
import { Sidebar } from '../../../../../components/sidebar'
import { Box } from '../../../../../components/box'
import { Form } from '../../../../../components/form'
import { Input } from '../../../../../components/input'
import { Button } from '../../../../../components/button'

export const ConfigPanel = ({
    open,
    battle,
    onChange,
    onClearBlocks,
    onClearPaths,
}) => {
    const [name, setName] = React.useState(battle.name)
    const [scale, setScale] = React.useState(battle.scale)

    if (!open) return
    return <Sidebar style={{ maxWidth: '25em' }}>
        <Box>
            <h3>Battle and Battlefield</h3>
            <Form gap='.5em'>
                <Box row style={{ justifyContent: 'space-between' }}>
                    <label>Battle name</label>
                    <Input style={{ width: 300 }} type="text" defaultValue={ name } onChange={ e => setName(e.target.value)}/>
                </Box>

                <Box row style={{ justifyContent: 'space-between' }}>
                    <label>Block scale</label>
                    <Input style={{ width: 300 }} type="number" defaultValue={ scale } onChange={ e => setScale(e.target.value) }/>
                </Box>

                <Button full onClick={() => onChange({ name, scale }) }>Save</Button>
            </Form>

            <hr style={{ width: '50%', margin: '1em auto' }}/>

            <h3 style={{ margin: 0 }}>Elements</h3>
            <Box gap="1em">
                <Box row style={{ justifyContent: 'space-between' }}>
                    <label>Clear all blocks ({ battle.blocks.length })</label>
                    { /* eslint-disable-next-line no-restricted-globals */ }
                    <Button full secondary onClick={ () => confirm('Are you sure?') && onClearBlocks() }>Clear</Button>
                </Box>

                <Box row style={{ justifyContent: 'space-between' }}>
                    <label>Clear all drawings ({ battle.paths.length })</label>
                    { /* eslint-disable-next-line no-restricted-globals */ }
                    <Button full secondary onClick={ () => confirm('Are you sure?') && onClearPaths() }>Clear</Button>
                </Box>
            </Box>
        </Box>
    </Sidebar>
}
