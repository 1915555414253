import React from 'react'
import styled from 'styled-components';
import { Box } from '../box';
import { Icon } from '../icons';

import {
    useNavigate,
} from "react-router";

import { useUser } from '../../context/user'

import { BugReportModal } from './bugReportModal'

const StyledProfile = styled.div`
    position: fixed;
    background-color: white;

    left: 0;
    top: 0;
    right: 0;

    ${ props => props.theme.desktop(`
        left: 1em;
        top: 1em;
        right: auto;

        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        cursor: default;
        z-index: 2;

        border: 1px solid ${ props.theme.colors.mainDark };
        border-radius: 5px;

    `) }

    &:hover .dropdown {
        height: auto;
    }
`

const Avatar = styled.div`
    width: 3em;
    height: 3em;

    ${ props => props.theme.desktop(`
        width: 2em;
        height: 2em;
    `) }

    background-color: ${ props => props.theme.colors.main };

    display: flex;
    justify-content: center;
    align-items: center;

    color: white;
    font-weight: bold;
`

const Email = styled.span`
    font-weight: bold;
    padding: 0 .5em 0 0;
`

const Dropdown = styled.div`
    width: 100%;
    height: 0;
    overflow: hidden;

    & > * {
        padding: 1em;

        ${ props => props.theme.desktop(`
            padding: .5em;
        `) }

        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 1em;
        cursor: pointer;

        &:hover {
            background-color: #EBEBEB;
            border-radius: 5px;
        }
    }
`

export const Profile = () => {
    const { user, logout } = useUser()
    const navigate = useNavigate()
    const [bugReportModalOpen, setBugReportModalOpen] = React.useState(false)

    if (!user) return null
    return (
        <>
        <StyledProfile>
            <Box row>
                <Avatar>
                    { user.email[0].toUpperCase() }
                </Avatar>
                <Email>
                    { user.email }
                </Email>
            </Box>
            <Dropdown className="dropdown">
                <div onClick={ () => navigate('/') }>
                    <Icon icon="map"/>
                    Battles
                </div>
                <div onClick={ () => setBugReportModalOpen(true) }>
                    <Icon icon="bug"/>
                    Report a bug
                </div>
                <div onClick={ logout }>
                    <Icon icon="arrow-right-from-bracket"/>
                    Logout
                </div>
            </Dropdown>
        </StyledProfile>
            <BugReportModal open={ bugReportModalOpen } onClose={ () => setBugReportModalOpen(false) }/>
        </>
    )
}
