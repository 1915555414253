import React from 'react'
import styled from 'styled-components'
import { v4 as uuid } from 'uuid'

import { Button } from '../../../../../components/button'
import { Link } from '../../../../../components/link'
import { Input } from '../../../../../components/input'
import { Box } from '../../../../../components/box'
import { Form } from '../../../../../components/form'
import { Select } from '../../../../../components/select'
import { Icon } from '../../../../../components/icons'

import { Block } from '../../../../../components/block'

const typeIcons = {
    1: 'star',
    2: 'person-rifle',
    3: 'horse-head',
    4: 'bomb',
    5: 'parachute-box',
}

export const Create = ({
    blocks,
    onNew,
    unit,
    setUnit,
    color,
    setColor,
}) => {
    // const [color, setColor] = React.useState('#000000')
    // const [unit, setUnit] = React.useState('')
    const [name, setName] = React.useState('')
    const [type, setType] = React.useState(1)
    const [units, setUnits] = React.useState({})
    const [blocksFile, setBlocksFile] = React.useState(null)
    const form = React.useRef()

    React.useEffect(() => {
        setUnits(blocks.reduce((l, block) => {
            if (!l[block.unit]) l[block.unit] = []
            l[block.unit].push(block)
            return l
        }, { }))
    },[ blocks ])

    const handleCreate = (e) => {
        e.preventDefault()
        onNew({
            uuid: uuid(),
            x: 50 + (10 * blocks.length),
            y: 10,
            s: 1,
            r: 0,
            color,
            type,
            name,
            unit,
        })
    }

    const createBlocksFromCSV = async (csv) => {
        const bs = csv.split(/[\n\r]{1,2}/)
        bs.forEach(async (data, idx) => {
            const [ type, color, name, unit ] = data.split(',')
            if (
                (!type || !color || !name || !unit) ||
                !Number.isInteger(+type) ||
                !/^#[a-fA-F0-9]{3,6}$/.test(color)
            ) return

            onNew({
                uuid: uuid(),
                x: 50 + (10 * (blocks.length + idx)),
                y: 10,
                s: 1,
                r: 0,
                color,
                type: +type,
                name,
                unit,
            })
        })
    }

    const handleImportCSV = () => {
        if (!blocksFile) return
        var reader = new FileReader();
        reader.onload = e => createBlocksFromCSV(e.target.result)
        reader.readAsText(blocksFile);
    }

    return <Box gap="2em">
        <Box>
            <Box>
                <h4>Create new block</h4>
                <Form onSubmit={ handleCreate} ref={ form }>
                    <Box row style={{ justifyContent: 'center' }}>
                        <Input full type="text" placeholder="block name" onChange={ e => setName(e.target.value) }/>
                        <Input full type="text" placeholder="unit name" defaultValue={ unit} onChange={ e => setUnit(e.target.value) }/>
                        <Box row style={{ justifyContent: 'center', flexWrap: 'nowrap' }}>
                            <Select onChange={ e => setType(+e.target.value)}>
                                <option value="1">officer</option>
                                <option value="2">Infantry</option>
                                <option value="3">cavalry</option>
                                <option value="4">artillery</option>
                                <option value="5">supply</option>
                            </Select>
                            <Input type="color" defaultValue={ color } onChange={ e => setColor(e.target.value) }/>
                            <Button type="submit">Create</Button>
                        </Box>
                    </Box>
                </Form>
            </Box>

            <Box>
                <h5>Or import from a CSV file</h5>
                <Box row style={{ justifyContent: 'space-between' }}>
                    <Input secondary type="file" accept={ '.csv' } onChange={ e => setBlocksFile(e.target.files[0]) }/>
                    <Button full disabled={ !blocksFile } onClick={ handleImportCSV }>Import</Button>
                </Box>
            </Box>
        </Box>
    </Box>
}
