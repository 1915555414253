import styled from 'styled-components'

export const Box = styled.div`
    display: flex;
    flex-direction: ${props => props.row ? 'row' : 'column'};
    gap: ${props => props.gap || '.5em'};
    align-items: ${props => props.align || 'center'};
    width: 100%;
    flex-wrap: wrap;
`
