import React from 'react'
import styled from 'styled-components'

import {
    FocusEvent,
    SelectModeEvent,
    SelectColorEvent,
    SetColorEvent,
    PathsVisibilityEvent,
    SetMapPinEvent,
    ZoomEvent,
} from '../../../game/events'
import modes from '../../../game/modes'
import { useBattle } from '../../../game/stores/battle'

import {
    SelectableIcon,

    HierarchyIcon,
    SelectIcon,
    DrawIcon,
    CameraIcon,
    ExportIcon,
    PinIcon,
    BugIcon,
    PlusIcon,
    MinusIcon,
    Icon,
} from '../../../components/icons'
import { Tooltip } from '../../../components/tooltip'

const StyledToolbar = styled.div`
position: absolute;
${ props => props.side && `${props.side}: 0;` }
// left: 0;
bottom: 1em;

margin: 0;
padding: 0.25em 1em;

display: flex;
justify-content: space-between;
flex-direction: column;
gap: 2em;
`

const ToolbarSection = styled.div`
    display: flex;
    gap: .5em;
    flex-direction: ${ props => props.direction || 'column' };

& div {
    display: flex;
    align-content: center;
    gap: .5em;
    & * {
        align-self: center;
    }
}
`

export const Toolbar = ({
    battle,

    onShowHierarchy,
    hierarchyOpen,

    onShowConfig,
    configOpen,

    onShowBugForm,

    onScreenshot,
    onMapPin,
    onExport,
    onExit
}) => {
    const [color, setColor] = React.useState('#e01b24')
    const [mode, setMode] = React.useState(modes.INTERACT)
    const [renderPaths, setRenderPaths] = React.useState(true)

    React.useEffect(() => {
        PathsVisibilityEvent.emit(renderPaths)
    }, [renderPaths])

    React.useEffect(() => {
        SelectColorEvent.emit(color)
    }, [color])

    React.useEffect(() => {
        SelectModeEvent.emit(mode)
    }, [mode])

    React.useEffect(() => {
        SetColorEvent.subscribe(({ detail }) => setColor(detail))

        return () => SetColorEvent.unsuscribe()
    }, [])

    if (!battle?.blocks) return null
    return (<>
        <StyledToolbar side='left'>
            <ToolbarSection>
                <Tooltip text="Pin camera position">
                    <SelectableIcon active={ true } onClick={ () => SetMapPinEvent.emit() }>
                        <PinIcon/>
                    </SelectableIcon>
                </Tooltip>

                <SelectableIcon active={ true } onClick={ () => ZoomEvent.emit(-1) }>
                    <PlusIcon/>
                </SelectableIcon>
                <SelectableIcon active={ true } onClick={ () => ZoomEvent.emit(1) }>
                    <MinusIcon/>
                </SelectableIcon>
            </ToolbarSection>

            <ToolbarSection>
                <Tooltip text="Enable selection mode">
                    <SelectableIcon active={ mode == modes.INTERACT } onClick={ e => setMode(modes.INTERACT) }>
                        <SelectIcon/>
                    </SelectableIcon>
                </Tooltip>
                <div>
                    <Tooltip text="Enable drawing mode">
                        <SelectableIcon active={ mode == modes.DRAW } onClick={ e => setMode(modes.DRAW) }>
                            <DrawIcon/>
                        </SelectableIcon>
                    </Tooltip>
                    { (mode == modes.DRAW) && <input type="color" value={ color } onChange={ e => setColor(e.target.value) }/> }
                </div>
                <Tooltip text="Toggle drawings visibility">
                    <SelectableIcon active={ renderPaths } onClick={ e => setRenderPaths(s => !s) }>
                        <Icon icon={ renderPaths ? 'eye' : 'eye-slash' }/>
                    </SelectableIcon>
                </Tooltip>
            </ToolbarSection>

            <ToolbarSection>
                {/* <Tooltip text="Take a snapshot"> */}
                {/*     <SelectableIcon active={ true } onClick={ onScreenshot }> */}
                {/*         <CameraIcon/> */}
                {/*     </SelectableIcon> */}
                {/* </Tooltip> */}

                <Tooltip text="Export game">
                    <SelectableIcon active={ true } onClick={ onExport }>
                        <ExportIcon/>
                    </SelectableIcon>
                </Tooltip>

                {/* <Tooltip text="Bug report"> */}
                {/*     <SelectableIcon active={ true } onClick={ () => onShowBugForm(true) }> */}
                {/*         <BugIcon/> */}
                {/*     </SelectableIcon> */}
                {/* </Tooltip> */}
                {/*  */}
                {/* <Tooltip text="Exit game"> */}
                {/*     <SelectableIcon active={ true } onClick={ onExit }> */}
                {/*         <Icon icon="right-from-bracket"/> */}
                {/*     </SelectableIcon> */}
                {/* </Tooltip> */}

            </ToolbarSection>
        </StyledToolbar>

        <StyledToolbar side='right'>
            <ToolbarSection direction='row'>
                <SelectableIcon active={ hierarchyOpen } onClick={ onShowHierarchy }>
                    <HierarchyIcon/>
                </SelectableIcon>

                <SelectableIcon active={ configOpen } onClick={ onShowConfig }>
                    <Icon icon="gear"/>
                </SelectableIcon>
            </ToolbarSection>
        </StyledToolbar>
    </>)
}
