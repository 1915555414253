import React from 'react'
import {
    Routes,
    Route,
    useNavigate,
} from "react-router";

import { BattlesProvider } from '../../context/battles'

import { Battles } from './battles';

export const Router = () => {
    const navigate = useNavigate();

    return (
        <BattlesProvider>
            <Routes>
                <Route>
                    <Route index element={<Battles
                        onSelect={ battle => navigate(`/game/${battle.id}`) }
                    />}/>
                </Route>
            </Routes>
        </BattlesProvider>
    )
}
