import React from 'react'
import { Toast } from '../../components/toast'

const Error = React.createContext()
Error.displayName = 'Error'

export const useError = () => React.useContext(Error)
export const ErrorProvider = ({ children }) => {
    const [error, setError] = React.useState(null)

    const raise = (message) => {
        setError(message)
        setTimeout(() => setError(null), 3000)
    }

    return <Error.Provider value={{ raise }}>
        { children }
        { error && <Toast>{ error }</Toast> }
    </Error.Provider>
}

