import React from 'react'
import styled from 'styled-components'
import { Box } from './box'
import { Icon } from './icons'

export const TYPE = {
    OFFICER: 1,
    INFANTRY: 2,
    CAVALRY: 3,
    ARTILLERY: 4,
    SUPPLY: 5,
}

export const typeIcons = {
    1: 'star',
    2: 'person-rifle',
    3: 'horse-head',
    4: 'bomb',
    5: 'parachute-box',
}

const StyledBlock = styled(Box)`
    width: fit-content;
    flex-direction: row;
    outline: 1px solid black;
    padding: .2em 1em;
    border-radius: 5px;
    border-top: 10px solid ${ props => props.color };
    font-size: .7em;
    flex-wrap: nowrap;
    white-space: nowrap;

    ${ props => props.onClick && `
        cursor: pointer;
        &:hover {
            transform: scale(1.01);
        }
    `}
`

export const Block = ({ block, onDelete, onClick }) => {
    return <StyledBlock color={ block.color } onClick={ onClick }>
        <Icon icon={ typeIcons[block.type] } style={{ color: block.color }}/>
        <p>{ block.name } ({ block.unit })</p>
        { !!onDelete && <Icon icon="trash-can" onClick={ () => onDelete(block.uuid) }/> }
    </StyledBlock>
}
