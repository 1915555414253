import { Texture, Sprite } from 'pixi.js'
import { Canvas } from '../../utils.js';
import { TiledMap } from '../../game/battlefield.js';

export const Battlefield = async (app, map) => {
    const mapCtx = Canvas()
    const tiledMap = await TiledMap(map)

    const backgroundTexture = Texture.from(mapCtx.canvas);
    const backgroundSprite = new Sprite(backgroundTexture);
    backgroundSprite.width = app.screen.width;
    backgroundSprite.height = app.screen.height;
    // backgroundSprite.interactive = true;
    // backgroundSprite.onDragMove = (_, delta) => camera.move(delta)

    const render = (camera) => {
        mapCtx.clearRect(0, 0, mapCtx.canvas.width, mapCtx.canvas.height)
        mapCtx.save()
        mapCtx.scale(camera.pos.z, camera.pos.z)
        mapCtx.translate(
            camera.pos.x + app.screen.width/2/camera.pos.z,
            camera.pos.y + app.screen.height/2/camera.pos.z
        )

        tiledMap.draw(mapCtx,
            -camera.pos.x - app.screen.width/2/camera.pos.z,
            -camera.pos.y - app.screen.height/2/camera.pos.z,
            mapCtx.canvas.width/camera.pos.z,
            mapCtx.canvas.height/camera.pos.z,
        )
        mapCtx.restore()
        backgroundTexture.baseTexture.update();
    }

    return {
        container: backgroundSprite,
        render,
    }
}
