import React from 'react'
import { Input } from '../../components/input'
import { Button } from '../../components/button'
import { Form } from '../../components/form'
import { Error } from '../../components/error'
import { email_rgx } from '../../utils'

export const Login = ({ onLogin, onBack }) => {
    const [email, setEmail] = React.useState(null)
    const [password, setPassword] = React.useState(null)
    const [error, setError] = React.useState(null)
    const [waiting, setWaiting] = React.useState(false)

    const handleSubmit = async (event) => {
        event.preventDefault()
        if (!email || !password) return setError('Missing fields')
        if (waiting) return

        setWaiting(true)
        const user = await onLogin(email, password)
        if (!user) setError(true)
        setWaiting(false)
    }

    React.useEffect(() => setError(null), [email, password])

    return (
        <Form onSubmit={ handleSubmit }>
            <Input placeholder="email" onChange={ e => setEmail(e.target.value) }/>
            <Input placeholder="password" type="password" onChange={ e => setPassword(e.target.value) }/>
            <Error style={{ maxWidth: 250 }}>{ error && 'Email or password incorrect.' }</Error>
            <Button type="submit" disabled={ !email || !password || waiting || !email_rgx.test(email) }>Login</Button>
            <Button secondary onClick={ onBack }>Back</Button>
        </Form>
    )
}
