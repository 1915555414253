export const email_rgx = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
export const password_rgx = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d#?!@$%^&*-]{8,}$/

export const Vec2 = {
    add: (a, b) => ({ x: a.x + b.x, y: a.y + b.y }),
    sub: (a, b) => ({ x: a.x - b.x, y: a.y - b.y }),
    mul: (a, b) => ({ x: a.x * b.x, y: a.y * b.y }),
    assign: (a, b) => { a.x = b.x; a.y = b.y },
}


export const canvas = (ref, config) => {
  const canvas = ref ? ref : document.createElement('canvas')
  canvas.width = config?.width || window.innerWidth
  canvas.height = config?.height || window.innerHeight
  canvas.ratio = canvas.width / canvas.height
  canvas.onresize = () => {
      canvas.width = window.innerWidth
      canvas.height = window.innerHeight
      canvas.ratio = canvas.width / canvas.height
  }
  const ctx = canvas.getContext('2d')
  return ctx
}
export const Canvas = canvas

/* eslint-disable-next-line */
export const loadImage = (src) => new Promise((res) => {
    const image = new Image()
    image.crossOrigin = "anonymous";
    image.src = src
    image.onload = () => res(image)
})

export const download = (data, filename, ext='json') => {
  const a = document.createElement('a')
  a.setAttribute('href', data)
  a.setAttribute('download', `${filename}.${ext}`)
  document.body.appendChild(a)
  a.click()
  a.remove()
}

export const exportJSON = (obj, filename) => download("data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(obj)), filename)
export const screenshot = (canvas, filename) => download(document.querySelector('canvas').toDataURL('png'), filename, 'png')
