import React from 'react'
import styled from 'styled-components'

export const Input = styled.input`
    box-sizing: border-box;
    border-radius: 5px;
    padding: .7em;
    width: ${ props => props.full ? '100%' : '250px' };
    max-width: ${ props => props.full ? '100%' : '250px' };

    border: 2px solid ${ props => props.theme.colors.secondary };
    color: ${ props => props.theme.colors.black };

    &[type="checkbox"] {
        appearance: none;
        width: .7em;
        height: .7em;
        border: 2px solid white;
        outline: 2px solid ${ props => props.theme.colors.main };
        border-radius: 5px;
        cursor: pointer;
        margin-right: .5em;

        &:checked {
            background: ${ props => props.theme.colors.main };
        }
    }
    

    &[type="color"] {
        height: 2.7em; 
        width: 100px;
        border: none;
        padding: 0;
        background: transparent;
        &::-webkit-color-swatch {
            padding: 0;
            border: none;
            border-radius: 5px;
            cursor: pointer;
        }
    }

    &[type="file"] {
        padding: 0;
        border: none;
        &::-webkit-file-upload-button {
            padding: .7em;
            background: ${ props => props.secondary ? props.theme.colors.secondary : props.theme.colors.main };
            color: white;
            border: none;
            border-radius: 5px;
            cursor: pointer;
    }
`
