import React from 'react'
import styled from 'styled-components'

import { v4 as uuid } from 'uuid'
import { useUser } from '../../context/user'

import { Modal } from '../../components/modal'
import { Button } from '../../components/button'
import { Input } from '../../components/input'
import { Uploader } from '../../components/fileUploader'
import { Form } from '../../components/form'
import { Box } from '../../components/box'
import { Error } from '../../components/error'

import { CreateMap } from '../../services/map'

export const ImportBattleModal = ({ open, onClose, onImport }) => {
    const { user } = useUser()
    const [name, setName] = React.useState('')
    const [scale, setScale] = React.useState(1)
    const [save, setSave] = React.useState(null)
    const [error, setError] = React.useState(null)
    const [waiting, setWaiting] = React.useState(false)

    const handleCreate = async () => {
        if (!save) return

        setWaiting(true)
        const created = await onImport({
            id: uuid(),
            user: user.id,
            name: save.name,
            map: save.map,
            scale: save.scale,
            blocks: save.blocks || [],
            paths: save.paths || [],
        }) 

        if (!created) {
            setError('Error: Cant import battle, please report this error in the discord')
            return setWaiting(false)
        }
    }

    const handleFileSelect = (event) => {
        const file = event.target.files[0]
        if (!file) return

        setWaiting(true)
        setError(null)
        const reader = new FileReader()
        reader.onload = async (event) => {
            try {
                const data = JSON.parse(event.target.result)
                if (!data || !data.name || !data.scale || !data.map) {
                    setError('Invalid file')
                    setSave(null)
                    return
                }
                setSave(data)
                setWaiting(false)
            } catch (e) {
                setError('Invalid file')
                setWaiting(false)
            }
        }
        reader.readAsText(file)
    }

    return (
        <Modal
            open={ open }
            onClose={ onClose }
            onSubmit={ handleCreate }
            disabled={ waiting || !save }
        >
            <h3>Create new battlefield</h3>
            <Box align="flex-start">
                <Input secondary style={{ width: 300 }} type="file" accept=".json" onChange={ handleFileSelect }/>
                <Error>{ error }</Error>
            </Box>
        </Modal>
    )
}
