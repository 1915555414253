import React from 'react'
import { FetchBattlefield, FetchBattle } from '../../../services/battle'
import { reducer } from './reducer'
import { SetBattleAction } from './actions'

export { watch } from './reducer'
export * from './actions'

const Battle = React.createContext()
Battle.displayName = 'Battle'

export const useBattle = () => React.useContext(Battle)

export const BattleProvider = ({ battleId, children }) => {
    const [battle, dispatch] = React.useReducer(reducer, null)

    React.useEffect(() => {
        console.log('fetch battle data', battleId)
        FetchBattle(battleId).then((data) => {
            dispatch(SetBattleAction(data))
        })
    }, [])

    return (
        <Battle.Provider value={{ battle, dispatch }}>
            { children }
        </Battle.Provider>
    )
}

