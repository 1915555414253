import {
    UpdateBattleConfig,
    UpdateBattleCamera,

    CreateBlocks,
    UpdateBlocks,
    DeleteBlocks,

    CreatePaths,
    DeletePaths,
} from '../../../services/battle'
import { actions } from './actions'


const watchers = []
export const watch = fn => watchers.push(fn)

export const reducer = (state = {}, action) => {
    let response
    switch(action.type) {
        case actions.SET:
            response = action.battle
            break

        case actions.SET_CONFIG:
            response = ({ ...state, ...action.config })
            UpdateBattleConfig(state.id, action.config)
            break
        case actions.SET_CAMERA:
            response = ({ ...state, ...action.camera })
            UpdateBattleCamera(state.id, action.camera)
            break
        // case actions.SET_NAME:
        //     response = ({ ...state, name: action.name })
        //     UpdateBattleConfig(response)
        //     break
        case actions.SET_BLOCKS:
            response = ({ ...state, blocks: action.blocks})
            break
        case actions.SET_PATHS:
            response = ({ ...state, paths: action.paths})
            break


        case actions.NEW_BLOCK:
            response = ({ ...state, blocks: [...state.blocks, action.block] })
            CreateBlocks(state, [ action.block ])
            watchers.forEach(fn => fn(response))
            return response
        case actions.UPDATE_BLOCKS:
            const blockIds = state.blocks.map(block => block.uuid)
            const toUpdate = action.blocks.reduce((l, block) => {
                if (blockIds.indexOf(block.uuid) == -1) return l
                return { ...l, [block.uuid]: block }
            }, {})

            response = ({ ...state, blocks: state.blocks.map(block => toUpdate[block.uuid] || block) })
            UpdateBlocks(state, action.blocks)
            watchers.forEach(fn => fn(response))
            return response
        case actions.DELETE_BLOCK:
            response = ({ ...state, blocks: state.blocks.filter(block => block.uuid != action.block ) })
            DeleteBlocks(state, [ action.block ])
            watchers.forEach(fn => fn(response))
            return response
        case actions.CLEAR_BLOCKS:
            response = ({ ...state, blocks: [] })
            DeleteBlocks(state, state.blocks.map(block => block.uuid))
            watchers.forEach(fn => fn(response))
            return response


        case actions.NEW_PATH:
            response = ({ ...state, paths: [...state.paths, action.path] })
            CreatePaths(state, [ action.path ])
            watchers.forEach(fn => fn(response))
            return response
        case actions.DELETE_PATH:
            response = ({ ...state, paths: state.paths.filter(path => path.uuid != action.path ) })
            DeletePaths(state, [ action.path ])
            watchers.forEach(fn => fn(response))
            return response
        case actions.CLEAR_PATHS:
            response = ({ ...state, paths: [] })
            DeletePaths(state, state.paths.map(path => path.uuid))
            watchers.forEach(fn => fn(response))
            return response
    }

    if (state && response) {
        watchers.forEach(fn => fn(response))
        // SaveBattle(response)
    }
    return response || state
}
