import React from 'react'
import styled from 'styled-components'

import { Form } from '../../components/form'
import { Button } from '../../components/button'
import { Input } from '../../components/input'
import { Error } from '../../components/error'
import { email_rgx, password_rgx } from '../../utils'

export const Register = ({ onRegister, onBack }) => {
    const [email, setEmail] = React.useState(null)
    const [password, setPassword] = React.useState(null)
    const [repassword, setRepassword] = React.useState(null)
    const [error, setError] = React.useState(null)
    const [waiting, setWaiting] = React.useState(false)

    const handleSubmit = async (event) => {
        event.preventDefault()
        if (!password || !email) return setError('Missing fields')
        if (!email_rgx.test(email)) return setError('Email incorrect')
        if (!(password_rgx.test(password))) return setError('Password must be minimum 8 characters and include at least one letter and one number')
        if (password != repassword) return setError('Password confirmation doesnt match')
        if (waiting) return

        setWaiting(true)
        const user = await onRegister(email, password)
        if (!user) setError('Can not create account')
        setWaiting(false)
    }

    React.useEffect(() => {
        setError(null)
    }, [email, password, repassword])

    return (<Form onSubmit={ handleSubmit }>
        <Input placeholder="email" onChange={ e => setEmail(e.target.value) }/>
        <Input placeholder="password" type="password" onChange={ e => setPassword(e.target.value) }/>
        <Input placeholder="repeat password" type="password" onChange={ e => setRepassword(e.target.value) }/>
        <Error style={{ maxWidth: 250 }}>{ error }</Error>
        <Button disabled={ !email || !email_rgx.test(email) || !password || password != repassword } type="submit">Register</Button>
        <Button secondary onClick={ onBack }>Back</Button>
    </Form>)

}
