export default {
    desktop: s => `
        @media only screen and (min-width: 600px) {
            ${s}
        }
    `,
    colors: {
        main: '#AF1E24',
        mainDark: '#8C1A1F',
        secondary: '#017A81',
        secondaryDark: '#015F63',
        light: '#F2F2F2',
        dark: '#333333',
    }
}
