import styled from 'styled-components';

export const Tooltip = styled.div`
position: relative;
display: flex;
align-items: center;
&:hover::after {
    content: '${props => props.text}';
    position: absolute;
    transform: translateX(${ props => props.left ? '-4em' : '4em' });
    padding: 0.5em;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    border-radius: 0.25em;
    font-size: 0.75em;
    white-space: nowrap;
}
`
