import styled from 'styled-components'

export const Toast = styled.div`
    position: fixed;
    top: 1em;
    right: 1em;
    padding: 1em;
    background-color: red;
    opacity: 0.9;
    color: white;
    font-weight: bold;
    border-radius: 4px;
    z-index: 1000;
`
