import styled from 'styled-components'

export const Button = styled.button`
    padding: .7em;

    background: ${ props => props.secondary ? props.theme.colors.secondary : props.theme.colors.main };
    border: 1px solid ${ props => props.secondary ? props.theme.colors.secondary : props.theme.colors.main };
    
    color: white;
    border-radius: 5px;

    display: flex;
    justify-content: center;
    cursor: pointer;
    text-decoration: none;
    
    width: ${ props => props.full ? '100%' : '250px' };
    max-width: ${ props => props.full ? '100%' : '250px' };

    ${ props => props.square && `
        width: 2.7em;
        height: 2.7em;
    `}

    &:hover {
        background: ${ props => props.secondary ? props.theme.colors.secondaryDark : props.theme.colors.mainDark };
    }

  ${props => props.active && `
        background: ${ props.theme.colors.mainDark };
  `}

  ${props => props.disabled && `
        opacity: .3;
        pointer: none!important;
        pointer-events: none;
  `}
`
