import { HTTPfetch } from './httpfetch'
const config = {
  api: '/api'
}

export const ERROR = {
  INVALID_FORMAT: 0x01,
  SERVER: 0x02,
}

export const UploadToCDN = async (prefix, filename, file) => {
  const form = await HTTPfetch(`${config.api}/cdn/signed?prefix=${prefix}&name=${ filename }`, {
    method: 'get',
    credentials: 'include'
  })
  if (form.error) return ({ error: ERROR.INVALID_FORMAT })
  const { url, fields } = form

  const formData = new FormData()
  Object.entries({ ...fields, file }).forEach(([key, value]) => formData.append(key, value))

  const upload = await HTTPfetch(url, {
    method: 'POST',
    body: formData,
  })
  if (upload.error) return ({ error: ERROR.SERVER })

  return { url, fields }
}

export const SendBug = async (text, metadata) => {
  const res = await fetch(`${config.api}/bugs`, {
    method: 'post',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      metadata,
      text,
    })
  })

  if(res.status !== 200) return false
  return res.json()
}
