import React from 'react'
import styled from 'styled-components'

export const Sidebar = styled.div`
position: absolute;
z-index: 9;
right: 1em;
top: 1em;
bottom: 5em;

margin: 0;
padding: 0.125em 1em;

display: flex;
flex-direction: column;
gap: .5em;

overflow: scroll;
overflow-x: hidden;

background: white;
border-radius: 5px;

-webkit-box-shadow: 10px 4px 21px -4px rgba(0,0,0,0.54);
-moz-box-shadow: 10px 4px 21px -4px rgba(0,0,0,0.54);
box-shadow: 10px 4px 21px -4px rgba(0,0,0,0.54);

div {
  h2:hover {
    color: #888;
  }
}
`
