import { Texture, Sprite } from 'pixi.js'
import { OutlineFilter } from 'pixi-filters';
import { render } from '../../game/blocks';

const onBlockDrag = (block, delta) => {
    block.x += delta.x
    block.y += delta.y
}

const onBlockDragEnd = (block) => {
}

const onBlockSelected = (block) => {
    block.filters = [new OutlineFilter(4, 0x99ff99)];
}

const onBlockDeselected = (block) => {
    block.filters = [];
}

export const Block = (data, scale) => {
    const texture = Texture.from(render(data))
    const block = new Sprite(texture);
    block.eventMode = 'static';
    block.cursor = 'pointer';
    block.anchor.set(0.5);
    block.scale.set(.1*scale);

    block.rotation = data.r;
    block.x = data.x;
    block.y = data.y;

    block.uuid = data.uuid;
    block.color= data.color
    block.type = data.type;
    block.flags = data.flags;
    block.name = data.name;
    block.unit = data.unit;

    block.selectable = true;
    block.onDragMove = onBlockDrag;
    block.onDragEnd = onBlockDragEnd;
    block.onSelected = onBlockSelected;
    block.onDeselected = onBlockDeselected;

    return block;
}
