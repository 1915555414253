import React from 'react'
import {
    FetchUserBattles,
    DeleteBattle,
    CreateBattle,
} from '../../services/battle'
import { useUser } from '../user'

const Battles = React.createContext()
Battles.displayName = 'Battles'

export const useBattles = () => React.useContext(Battles)
export const BattlesProvider = ({ children }) => {
    const { user } = useUser()
    const [battles, setBattles] = React.useState([])
    const [loading, setLoading] = React.useState(false)

    const create = async (data) => { 
        setLoading(true)
        const result = await CreateBattle(data)
        if (!result || result.error) {
            setLoading(false)
            return false
        }

        const b = await FetchUserBattles(user.id)
        if (!b || b.error) {
            setLoading(false)
            return false
        }

        setBattles(b)
        setLoading(false)
        return result
    }

    const remove = async (id) => {
        setLoading(true)
        await DeleteBattle(id)

        const b = await FetchUserBattles(user.id)
        if (!b || b.error) {
            setLoading(false)
            return false
        }

        setBattles(b)
        setLoading(false)
        return true
    }

    React.useEffect(() => {
        if (!user) return
        setLoading(true)
        FetchUserBattles().then(b => {
            setBattles(b)
            setLoading(false)
        })
    }, [user])

    if (loading) {
        return <div>Loading...</div>
    }
    return <Battles.Provider value={{ battles, create, remove }}>
        { children }
    </Battles.Provider>
}

