import React from 'react'

import { useUser } from '../../context/user'
import { useBattles } from '../../context/battles'

// import { TopBar } from '../../components/topbar'
import { Container } from '../../components/container'
import { Panel } from '../../components/panel'
import { Box } from '../../components/box'
import { Button } from '../../components/button'
import { PlusIcon } from '../../components/icons'

import { FetchMap } from '../../services/map'
import { NewBattleModal } from './newBattleModal'
import { ImportBattleModal } from './importBattleModal'

import { BattleCard, Battle } from './card'

const MODAL = {
    NONE: 0,
    NEW_BATTLE: 1,
    IMPORT_BATTLE: 2,
}

export const Battles = ({ onSelect }) => {
    const {user, logout} = useUser()
    const { battles, create, remove } = useBattles()
    const [newBattleOpen, setNewBattleOpen] = React.useState(false)
    const [openModal, setOpenModal] = React.useState(MODAL.NONE)
    // const [openModal, setOpenModal] = React.useState(MODAL.IMPORT_BATTLE)

    const [maps, setMaps] = React.useState(null)
    const [loading, setLoading] = React.useState(true)

    const handleCreateBattle = async (data) => {
        const result = await create(data)
        if (result) setOpenModal(MODAL.NONE)
        return result
    }

    React.useEffect(() => {
        const promises = battles.map(({ map }) => FetchMap(map))
        Promise.all(promises).then(m => {
            setMaps(m.reduce((l, map) => ({ ...l, [map.id]: map }), {}))
            setLoading(false)
        })
    }, [battles])

    if (loading) return <h1>Loading</h1>
        return (<div style={{ paddingTop: '2em' }}>
            {/* <TopBar user={ user } onLogout={ logout }/> */}

            <Container style={{
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'center',
                padding: '3em',
            }}>
                <BattleCard>
                    <PlusIcon style={{ fontSize: '200px', color: '#ebebeb'}}/>
                    <Box gap=".5em">
                        <Button full onClick={ () => setOpenModal(MODAL.NEW_BATTLE) }>Create new Battle</Button>
                        <Button full secondary onClick={ () => setOpenModal(MODAL.IMPORT_BATTLE) }>Import Battle</Button>
                    </Box>
                </BattleCard>

                { battles.map(battle => 
                <Battle
                    key={ battle.id }
                    battle={ battle }
                    map={ maps[battle.map] }
                    onJoin={ () => onSelect(battle) }
                    onDelete={ remove }
                />
                )}
            </Container>

            <NewBattleModal
                open={ openModal == MODAL.NEW_BATTLE }
                onClose={ () => setOpenModal(MODAL.NONE) }
                onCreate={ handleCreateBattle }
            />

            <ImportBattleModal
                open={ openModal == MODAL.IMPORT_BATTLE }
                onClose={ () => setOpenModal(MODAL.NONE) }
                onImport={ handleCreateBattle }
            />
        </div>)
}
