import { Infantry } from './infantry'
import { Artillery } from './artillery'
import { Cavalry } from './cavalry'
import { Supply } from './supply'
import { FLAGS } from './flags'

export const OFFICER = 0x01
export const INFANTRY = 0x02
export const CAVALRY = 0x03
export const ARTILLERY = 0x04
export const SUPPLY = 0x05

export { FLAGS }

const BLOCK_WIDTH = 300
const BLOCK_HEIGHT = 80

const renderCache = {}
export const render = (block) => {
  const k = JSON.stringify(block)

  if (!renderCache[k]) {
    let c = renderCache[k] = document.createElement('canvas')
    c.width = BLOCK_WIDTH - ((+block.type == OFFICER) * (BLOCK_WIDTH - BLOCK_HEIGHT))  - ((+(block.type == ARTILLERY || block.type == CAVALRY)) * (BLOCK_WIDTH/2)) 
    c.height = BLOCK_HEIGHT
    const ctx = c.getContext('2d')

    ctx.strokeStyle = ctx.fillStyle = block.color

    ctx.save()
    ctx.lineWidth = 2

    switch (block.type) {
      case OFFICER:
        ctx.fillRect(0, 0, BLOCK_HEIGHT, BLOCK_HEIGHT)
        ctx.filter = 'brightness(140%)'
        ctx.fillRect(0, 0, BLOCK_HEIGHT , BLOCK_HEIGHT / 2)
        ctx.strokeRect(0, 0, BLOCK_HEIGHT, BLOCK_HEIGHT)

        ctx.fillStyle = '#222'
        ctx.font = "bold small-caps 20px Arial";
        ctx.fillText(block.name, (BLOCK_HEIGHT / 2) - (block.name.length / 2 * 12), BLOCK_HEIGHT/4)

        ctx.font = "bold small-caps 16px Arial";
        ctx.fillText(block.unit, (BLOCK_HEIGHT / 2) - (block.unit.length / 2 * 8), BLOCK_HEIGHT - 10)

        break;
      case INFANTRY:
        ctx.drawImage(Infantry(block), 0, 0)
        break;
      case CAVALRY:
        ctx.drawImage(Cavalry(block), 0, 0)
        break;
      case ARTILLERY:
        ctx.drawImage(Artillery(block), 0, 0)
        break;
      case SUPPLY:
        ctx.drawImage(Supply(block), 0, 0)
        break;
    }

    const marks = []
    const n = (+!!(block.flags & FLAGS.THREE) * 3) || (+!!(block.flags & FLAGS.TWO) * 2) || +!!(block.flags & FLAGS.ONE)
    if (n) marks.push(n)
    if (block.flags & FLAGS.ROUTED) marks.push('\uF70c')
    if (block.flags & FLAGS.LOW_AMMO) marks.push('\uF4cd')

    renderCache[k] = { ctx, marks }
  }

  renderCache[k].ctx.save()
  renderCache[k].ctx.font='20px FontAwesome';
  renderCache[k].ctx.fillStyle = '#a00'
  renderCache[k].marks.forEach((mark, idx) => {
    const pos = { x: renderCache[k].ctx.canvas.width - renderCache[k].ctx.canvas.height*.22 - (renderCache[k].ctx.canvas.height * .22 * idx * 2), y: renderCache[k].ctx.canvas.height*.75}
    renderCache[k].ctx.save()

    if (block.inverted) {
      renderCache[k].ctx.translate(renderCache[k].ctx.canvas.width, renderCache[k].ctx.canvas.height)
      renderCache[k].ctx.scale(-1, -1)
    }

    renderCache[k].ctx.beginPath()
    renderCache[k].ctx.arc(pos.x, pos.y, renderCache[k].ctx.canvas.height/5, 0, 2*Math.PI)
    renderCache[k].ctx.fill()
    renderCache[k].ctx.fillStyle = 'white'
    renderCache[k].ctx.fillText(mark, pos.x - 8, pos.y + 8)
    renderCache[k].ctx.restore()
  })

  renderCache[k].ctx.restore()

  return renderCache[k].ctx.canvas
}
