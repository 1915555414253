import React from 'react'
import styled from 'styled-components'
import { Input } from '../../../../../components/input'
import { Block } from '../../../../../components/block'
import { Box } from '../../../../../components/box'
import { Link } from '../../../../../components/link'

import {
    FocusEvent,
    UnitVisibilityEvent,
} from '../../../../../game/events'

const Collapsible = styled.div`
    height: 0;
    overflow: hidden;

    display: flex;
    flex-wrap: wrap;
    gap: .7em;

    ${props => props.open && `
        height: auto;
    `}
`

export const Hierarchy = ({
    blocks,
    onDelete,
    handleNewBlockForUnit,
}) => {
    const [units, setUnits] = React.useState({})
    const [unitOpen, setUnitOpen] = React.useState(false)
    const [unitVisibility, setUnitVisibility] = React.useState({})

    const handleUnitVisibility = (unit, visibility) => {
        setUnitVisibility(s => ({ ...s, [unit]: visibility }))
    }

    const handleDelete = (uuid) => {
        /* eslint no-restricted-globals: 0 */
        confirm('Are you sure?') && onDelete(uuid)
    }

    React.useEffect(() => {
        UnitVisibilityEvent.emit(unitVisibility)
    }, [unitVisibility])

    React.useEffect(() => {
        const u = blocks.reduce((l, block) => {
            if (!l[block.unit]) l[block.unit] = []
            l[block.unit].push(block)
            return l
        }, {})
        setUnits(u)
        setUnitVisibility(Object.keys(u).reduce((l, unit) => ({ ...l, [unit]: true }), {}))
    },[ blocks])

    return <Box style={{ justifyContent: 'flex-start', alignItems: 'flex-start' }}>
        { Object.keys(units).sort().map(unit => <div key={ unit }>
            <Box row>
                <Input type="checkbox" checked={ unitVisibility[unit] }
                    onChange={ e => handleUnitVisibility(unit, e.target.checked) }
                />
                <h4 style={{ cursor: 'pointer' }} onClick={ () => setUnitOpen(s => s == unit ? null : unit) }>{unit}</h4>
                <span style={{ fontSize: '.7em'}}>
                    (<Link onClick={ () => handleNewBlockForUnit(unit, units[unit][0].color) }>Add Block</Link>)
                </span>
            </Box>
            <Collapsible open={ unit == unitOpen }>
                <Box row style={{ padding: '.5em' }}>
                    { units[unit].map(block => <Block key={ block.uuid }
                        block={ block }
                        onClick={ () => FocusEvent.emit(block) }
                        onDelete={ handleDelete }
                    />) }
                </Box>
            </Collapsible>
        </div>) }
    </Box>
}
