import styled from 'styled-components'
import { Box } from './box'

export const Panel = styled(Box)`
    background: white;
    padding: 2em;
    border-radius: 5px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
    -- width: 260px;
    width: fit-content;
    height: auto;
    box-sizing: border-box;
`
