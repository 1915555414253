import React from 'react'
import styled from 'styled-components'
import {
    Routes,
    Route,
    useParams,
    useNavigate,
} from "react-router";

import { FetchBattle } from '../../services/battle'
import { FetchMap } from '../../services/map'
// import { Game } from '../../game'
import { Game } from '../../gg'
import { UI } from './ui'

import { BattleProvider, useBattle } from '../../game/stores/battle'

const Canvas = styled.canvas`
width: 100%;
height: 100%;
`

const GameInstance = () => {
    const { battle, dispatch } = useBattle()

    const canvas = React.useRef(null)
    const [map, setMap] = React.useState(null)
    const [loading, setLoading] = React.useState(false)
    const navigate = useNavigate()

    React.useEffect(() => {
        if (!battle || loading || battle?.map == map?.id) return

        FetchMap(battle.map).then((m) => {
            setMap(m)
            setLoading(false)
        })
    }, [battle])

    React.useEffect(() => {
        if (!map || !canvas.current) return
        Game(canvas.current, battle, map, dispatch)
    }, [canvas, map])

    const resize = () => {
        if (!canvas.current) return
        canvas.current.width = window.innerWidth
        canvas.current.height = window.innerHeight
    }

    React.useEffect(() => {
        window.addEventListener('resize', resize)
        resize()
        return () => window.removeEventListener('resize', resize)
    }, [])

    if (!battle || loading) return <p>Loading...</p>
    return <>
        <Canvas ref={ canvas }/>
        <UI
            canvas={ canvas.current}
            onExit={ () => navigate('/') }
        />
    </>
}

const Battle = () => {
    const { id } = useParams();

    return (
        <BattleProvider battleId={ id }>
            <GameInstance/>
        </BattleProvider>
    )
}

export const Router = () => {
    return (
        <Routes>
            <Route>
                <Route index path=":id" element={<Battle/>}>
                </Route>
            </Route>
        </Routes>
    )
}
