import React from 'react'
import styled from 'styled-components'
import { Panel } from '../../components/panel'
import { Box } from '../../components/box'
import { Button } from '../../components/button'

export const BattleCard = styled(Panel)`
    padding: 1em;
    width: 250px;
    min-height: 400px;
    height: fit-content;
    gap: 0;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    :hover {
        transform: scale(1.01);
    }

    h4 {
        margin: 0;
        padding: 0;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
        overflow: hidden;
    }
`

export const Battle = ({ battle, map, onJoin, onDelete }) => {
    if (!map || !battle) return null
    return (
        <BattleCard>
            <img
                width="100%"
                src={
                    map.tiles[
                        map.width * Math.floor(map.height / 2) + Math.floor(map.width / 2)
                    ]
                }
            />
            <h4>{ battle.name }</h4>
            <Box gap=".5em">
                <Button full onClick={ onJoin }>Join</Button>
                {/* eslint-disable no-restricted-globals */}
                <Button full secondary onClick={ (e) => confirm('Are you sure?') && onDelete(battle.id) }>Delete</Button>
            </Box>
        </BattleCard>
    )
}
