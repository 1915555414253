import React from 'react'
import styled from 'styled-components'
import { Sidebar } from '../../../../../components/sidebar'
import { Box } from '../../../../../components/box'

import { Hierarchy } from './hierarchy'
import { Create } from './create'

export const BlocksPanel = ({ open, blocks, onCreate, onDelete }) => {
    const top = React.useRef()
    const [color, setColor] = React.useState('#000000')
    const [name, setName] = React.useState('')
    const [unit, setUnit] = React.useState('')

    const handleCreate = (block) => {
        onCreate(block)
    }

    const handleNewBlockForUnit = (unit, color) => {
        setUnit(unit)
        setColor(color)
        top.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }

    const handleDelete = (blockId) => {
        onDelete(blockId)
    }

    if (!open) return
    return <Sidebar style={{ maxWidth: '25em' }}>
        <span ref={ top }/>
        <Create
            blocks={ blocks }
            onNew={ handleCreate }
            unit={ unit }
            setUnit={ setUnit }
            color={ color }
            setColor={ setColor }
        />
        <hr width="50%"/>
        <Hierarchy
            blocks={ blocks }
            onDelete={ handleDelete }
            handleNewBlockForUnit={ handleNewBlockForUnit }
        />
    </Sidebar>
}
