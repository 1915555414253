import React from 'react'
import { canvas, loadImage } from '../../utils'
import { v4 as uuid } from 'uuid'
import { useUser } from '../../context/user'

import styled from 'styled-components'
import { Modal } from '../../components/modal'
import { Button } from '../../components/button'
import { Input } from '../../components/input'
import { Uploader } from '../../components/fileUploader'
import { Form, FormItem } from '../../components/form'
import { Box } from '../../components/box'
import { ProgressBar } from '../../components/progressbar'

import { UploadToCDN, ERROR } from '../../services/platform'
import { CreateMap } from '../../services/map'

const ErrorMessage = styled.span`
  color: red;
  font-size: .75em;
  max-width: 20em;
`

function dataURItoBlob(dataURI) {
    var binary = atob(dataURI.split(',')[1]);
    var array = [];
    for(var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {type: 'image/png'});
}

export const NewBattleModal = ({ open, onClose, onCreate }) => {
    const id = uuid()
    const { user } = useUser()
    const [name, setName] = React.useState('')
    const [map, setMap] = React.useState('')
    const [scale, setScale] = React.useState(1)
    const [error, setError] = React.useState(null)
    const [waiting, setWaiting] = React.useState(false)
    const [progress, setProgress] = React.useState({ progress: 0 })

    React.useEffect(() => {
        setError(null)
    }, [name, map, scale])

    const updateProgress = (p) => new Promise((res ) => setTimeout(() => {
        setProgress(s => ({ progress: p }))
        res()
    }, 1))

    const handleCreate = async (event) => {
        event.preventDefault()
        if (waiting || !name || !map || !scale) {
            setError('Missing information')
            console.log(waiting, name, map, scale)
            return false
        }
        setWaiting(true)

        const image = await loadImage(URL.createObjectURL(map))
        const maxSize = 500
        const ctx = canvas(null, { width: maxSize, height: maxSize })

        const w = Math.ceil(image.width/maxSize)
        const h = Math.ceil(image.height/maxSize)
        const length = w*h

        const steps = length + 1
        let done = 0

        const mapId = uuid()
        const tiles = []
        for (let i = 0; i < length; i++) {
            const col = i % w
            const row = Math.floor(i / w)
            ctx.clearRect(0, 0, maxSize, maxSize)
            ctx.drawImage(image,
                col * maxSize, row * maxSize,
                maxSize, maxSize,
                0, 0,
                maxSize, maxSize,
            )
            const data = ctx.canvas.toDataURL('png')
            const upload = await UploadToCDN(mapId, `${i}.png`, dataURItoBlob(data))
            if (upload.error) {
                if (upload.error == ERROR.INVALID_FORMAT) console.log('Invalid map format, must be a .jpg, .png or .jpeg image')
                if (upload.error == ERROR.SERVER) console.log('Error: Cant upload image, please report this error in the discord')
            }
            tiles.push(upload.url + upload.fields.key)
            done = i
            await updateProgress(done/steps)
        }

        const tiledmap = await CreateMap({
            id: mapId,
            tileSize: maxSize,
            width: w,
            height: h,
            tiles,
        })
        if (tiledmap.error) {
            if (tiledmap.error == ERROR.INVALID_FORMAT) setError('Invalid map format, must be a .jpg, .png or .jpeg image')
            if (tiledmap.error == ERROR.SERVER) setError('Error: Cant upload image, please report this error in the discord')
            return setWaiting(false)
        }
        await updateProgress(++done/steps)

        const created = await onCreate({
            id: uuid(),
            user: user.id,
            name,
            map: mapId,
            scale: parseFloat(scale)
        }) 
        if (!created) {
            setError('Error: Cant create battle, please report this error in the discord')
            return setWaiting(false)
        }

        await updateProgress(1)
        setWaiting(false)
        return created
    }

    return <Modal
        open={ open }
        disabled={ waiting || !name || !scale || !map }
        disabledAll={ waiting }
        onClose={ onClose }
        onSubmit={ handleCreate }
    >
        <h3>Create new battlefield</h3>
            <Box row style={{ justifyContent: 'space-between' }}>
                <label>Battlefield name</label>
                <div>
                    <Input style={{ width: 300 }} type="text" defaultValue={ name } onChange={ e => setName(e.target.value)}/>
                </div>
            </Box>

            <Box row style={{ justifyContent: 'space-between' }}>
                <label>Battlefield map</label>
                <div>
                    <Input secondary style={{ width: 300 }} type="file" accept=".png,.jpg" onChange={ e => setMap(e.target.files[0]) }/>
                </div>
            </Box>

            <Box row style={{ justifyContent: 'space-between' }}>
                <label>Block scale</label>
                <div>
                    <Input style={{ width: 300 }} type="number" defaultValue={ scale } onChange={ e => setScale(e.target.value) }/>
                </div>
            </Box>
            <ErrorMessage>{ error }</ErrorMessage>

            {
                waiting && <ProgressBar progress={ progress.progress }/>
            }
    </Modal>
}
