import React from 'react'

import {
    useNavigate,
    useLocation,
    useSearchParams,
} from 'react-router'
import { Login, Register, Session } from '../../services/user'

const User = React.createContext()
User.displayName = 'User'

const getCookie = (name) => {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)
    if (parts.length === 2) return parts.pop().split(';').shift()
}

export const useUser = () => React.useContext(User)
export const UserProvider = ({ children }) => {
    const navigate = useNavigate()
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const [token, setToken] = React.useState(null)
    const [user, setUser] = React.useState(null)
    const [loading, setLoading] = React.useState(true)

    const login = async (email, password) => {
        const result = await Login({ email, password })
        if (!result) return false
        setUser(result)
        return result
    }

    const create = async (email, password) => {
        const result = await Register({ email, password })
        if (!result) return false
        setUser(result)
        return result
    }

    const logout = () => {
        setUser(null)
        setToken(null)
        document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
    }

    React.useEffect(() => {
        const token = getCookie('token')
        if (!token) {
            setLoading(false)
            // if (!/^\/auth/.test(location.pathname)) {
            //     console.log('nav', location)
            //     navigate('/auth')
            // }
            return
        }

        Session().then(session =>{
            setLoading(false)
            if (session) setUser(session)
        })
    }, []);

    React.useEffect(() => {
        if (user && /^\/auth/.test(location.pathname)) {
            navigate(searchParams.get('from') || '/')
        }
    }, [user])

    if (loading) {
        return <div>Loading...</div>
    }
    return <User.Provider value={{ user, login, create, logout }}>
        { children }
    </User.Provider>
}

