import React from 'react'
import { Modal } from '../modal'
import { Box } from '../box'
import { Button } from '../button'
import { Form } from '../form'

import { useLocation } from "react-router";

import { SendBug } from '../../services/platform'

export const BugReportModal = ({ open, onClose }) => {
    const location = useLocation()
    const [text, setText] = React.useState('')

    const send = async (e) => {
        e.preventDefault()
        await SendBug(text, { path: location.pathname })
        setText('')
        onClose()
    }

    return (
        <Modal
            open={ open }
            onSubmit={ send }
            onClose={ onClose }
        >
            <h2 style={{ margin: 0 }}>Have you find a bug or a missfunction?</h2>
            <h4 style={{ margin: 0 }}>Describe it as clear as possible</h4>

            <Box>
                <textarea rows="4" cols="50"
                    style={{ width: '100%', boxSizing: 'border-box' }}
                    onChange={ e => setText(e.target.value) }
                />
                <span style={{ fontSize: '0.8em', maxWidth: 400 }}>
                    Thank you for helping me to improve the app! This is a hobby project built by a single person to satisfy personal needs, there are tons of bugs, known and unknown, so your feedback is very important to me.
                </span>
            </Box>
        </Modal>
    )
}
