import * as PIXI from 'pixi.js'
import { v4 as uuid } from 'uuid'
import { TiledMap } from '../game/battlefield.js';
import { Camera } from '../game/camera.js';
import { Canvas } from '../utils.js';
import sha1 from 'sha1'

import modes from '../game/modes'

import {
    watch,

    NewPathAction,
    DeletePathAction,
    UpdateBlocksAction,
    SetCameraAction,
} from '../game/stores/battle'

import { mouse, isPressed,
    bind as BindInput,
} from '../context/input'

import {
    FocusEvent,
    SelectModeEvent,
    SelectColorEvent,
    SetColorEvent,
    SetMapPinEvent,
    UnitVisibilityEvent,
    PathsVisibilityEvent,
    ZoomEvent,
} from '../game/events'

import { Block } from './entities/block';
import { Battlefield } from './entities/battlefield';

const Path = (data, width) => {
    const container = new PIXI.Container()
    container.uuid = data.uuid;
    container.interactive = true
    const graphics = new PIXI.Graphics();
    container.addChild(graphics)
    graphics.moveTo(data.points[0], data.points[1]);
    for (let i = 2; i < data.points.length; i+=2) {
        graphics.lineTo(data.points[i], data.points[i+1]);
    }
    graphics.stroke({
        width,
        color: data.color,
        alpha: 1,
        native: true,
        cap: 'round',
        join: 'round',
    });

    return container
}

const PATHS_LAYER = 0x01
const BLOCKS_LAYER = 0x02

const Bridge = ({ battle, dispatch, onBlockUpdate, onBlockCreate, onBlockDestroy }) => {
    let battleData = battle
    const block_cache = {}
    const save_span = 1000
    let last_save = Date.now()

    let toSave = new Set()
    const save = ({ blocks }) => {
        const ids = blocks.map(({ uuid }) => uuid)
        toSave = new Set([ ...toSave, ...ids ])

        const now = Date.now()
        if (now - last_save < save_span) return

        const data = [...toSave].map(uuid => {
            const { block } = block_cache[uuid]
            return ({
                uuid: block.uuid,
                x: block.x,
                y: block.y,
                s: block.scale.x/.1,
                r: block.rotation,
                color: block.color,
                type: block.type,
                flags: block.flags,
                name: block.name,
                unit: block.unit,
            })
        })
        dispatch(UpdateBlocksAction(data))
        toSave.clear()
        last_save = now
    }

    const createBlock = (blockData) => {
        const hash = sha1(JSON.stringify(blockData)) 
        if (block_cache[blockData.uuid] && block_cache[blockData.uuid].hash == hash) {
            return block_cache[blockData.uuid].block
        }

        const block = Block(blockData, battleData.scale)
        block_cache[blockData.uuid] = {
            block,
            hash
        }
        onBlockCreate && onBlockCreate(block)
        return block
    }

    const processBlock = (blockData) => {
        const v = block_cache[blockData.uuid]
        if (!v) return createBlock(blockData)

        const { block, hash } = v

        const checksum = sha1(JSON.stringify(blockData)) 
        if (hash == checksum) {
            return block
        }

        block.scale.set(.1 * battle.scale);
        block.rotation = blockData.r;
        block.x = blockData.x;
        block.y = blockData.y;
        block.uuid = blockData.uuid;
        block.color= blockData.color
        block.type = blockData.type;
        block.flags = blockData.flags;
        block.name = blockData.name;
        block.unit = blockData.unit;
        block_cache[blockData.uuid].hash = checksum

        onBlockUpdate && onBlockUpdate(block)
    }

    const destroyBlock = (uuid) => {
        const block = block_cache[uuid]
        if (!block) return
        onBlockDestroy && onBlockDestroy(block)
        block.block.destroy()
        delete block_cache[uuid]
    }

    const processBlocks = (data) => {
        const toProcess = Object.keys(block_cache)

        data.blocks.forEach(data => {
            processBlock(data)
            toProcess.splice(toProcess.indexOf(data.uuid), 1)
        })
        if (toProcess.length) toProcess.forEach(destroyBlock)
    }
    processBlocks(battleData)

    let version = sha1(JSON.stringify(battleData))
    watch((u) => {
        let n = sha1(JSON.stringify(u))
        if (n == version) return

        battleData = u
        version = n
        processBlocks(battleData)
    })

    return { save }
}

export const Game = async (canvas, battle, map, dispatch) => {
    let mode = modes.INTERACT
    let color = '#e01b24'
    let layerMask = PATHS_LAYER | BLOCKS_LAYER

    BindInput()

    const app = new PIXI.Application()
    await app.init({ canvas, resizeTo: window, antialias: true, backgroundAlpha: 0, })
    app.stage.interactive = true
    app.stage.onDragMove = (_, delta) => camera.move(delta)

    const camera = Camera({
        x: battle.camera.x || -app.screen.width/2,
        y: battle.camera.y || -app.screen.height/2,
        z: battle.camera.z || 1,
        minZoom: 10
    })

    const battlefield = await Battlefield(app, map)
    const selectionBoxGraphics = new PIXI.Graphics();

    const worldContainer = new PIXI.Container()
    worldContainer.scale.set(camera.pos.z)
    worldContainer.interactive = false

    const blocksContainer = new PIXI.Container()
    const pathsContainer = new PIXI.Container()
    pathsContainer.interactive = false
    worldContainer.addChild(blocksContainer, pathsContainer)


    let blockLayers = {}
    let blocks = []

    const onBlockCreate = (block) => {
        if (!blockLayers[block.unit]) {
            blockLayers[block.unit] = new PIXI.Container()
            blocksContainer.addChild(blockLayers[block.unit])
        }

        blockLayers[block.unit].addChild(block);
        blocks.push(block)
    }

    // const onBlockUpdate = console.log
    // const onBlockDestroy = console.log

    const bridge = Bridge({
        battle,
        dispatch,
        onBlockCreate,
        // onBlockUpdate,
        // onBlockDestroy
    })

    const paths = []
    const updatePaths = () => {
        pathsContainer.removeChildren()
        paths.length = 0
        battle.paths.forEach(data => {
            const path = Path(data, 3 * battle.scale)
            paths.push(path)
            pathsContainer.addChild(path)
        })
    }
    updatePaths()


    // let updated = []
    let selected = []
    let dragTargets = [];
    let selectionRect = null;

    app.stage.eventMode = 'static';
    app.stage.hitArea = app.screen;
    app.stage.on('pointerdown', onPointerDown);
    app.stage.on('rightdown', onRightDown);
    app.stage.on('pointerup', onDragEnd);
    app.stage.on('pointerupoutside', onDragEnd);

    let path = null
    let drawing = null

    function onPointerDown(event) {
        if (event.data.button == 2) return
        if (mode == modes.DRAW) {

            const x = (event.data.global.x - app.screen.width/2)/camera.pos.z - camera.pos.x
            const y = (event.data.global.y - app.screen.height/2)/camera.pos.z - camera.pos.y
            path = {
                x,
                y,
                s: .5,
                color: color,
                points: [x, y],
                uuid: uuid()
            }

            drawing = new PIXI.Graphics();
            drawing.uuid = uuid();
            drawing.lineStyle(3*battle.scale, path.color, 1);
            drawing.moveTo(x, y);
            paths.push(drawing)
            pathsContainer.addChild(drawing)

            onDragStart(event)
        }

        if (selected.length && isPressed('Control')) {
            const delta = {
                x: (event.data.global.x - app.screen.width/2)/camera.pos.z - camera.pos.x - selected[0].x,
                y: (event.data.global.y - app.screen.height/2)/camera.pos.z - camera.pos.y - selected[0].y
            }

            selected.forEach(block => {
                block.x += delta.x
                block.y += delta.y
            })

            bridge.save({ blocks: selected })
            return
        }


        if (!event.target) return

        const isSelected = selected.some(block => block.uuid == event.target?.uuid)
        if (!isSelected && !isPressed('Shift')) {
            selected.forEach(block => block.onDeselected && block.onDeselected(block))
            selected = []
        }

        event.target.onPointerDown && event.target.onPointerDown(event, event.target);

        if (!isSelected && event.target.selectable) {
            selected.push(event.target)
            event.target.onSelected && event.target.onSelected(event.target)
        }

        drag(event, event.target)
    }

    function onRightDown(event) {
        if (mode == modes.DRAW) {
            if (event.target.uuid) {
                const p = pathsContainer.children.find(p => p.uuid == event.target.uuid)
                p && p.destroy()
                dispatch(DeletePathAction(event.target.uuid))
            }
            return
        }

        selectionRect = {
            x0: event.data.global.x,
            y0: event.data.global.y,
        }
        app.stage.on('pointermove', onDragMove);
    }

    function onDragMove(event) {
        if (mode == modes.DRAW) {
            const x = (event.data.global.x - app.screen.width/2)/camera.pos.z - camera.pos.x
            const y = (event.data.global.y - app.screen.height/2)/camera.pos.z - camera.pos.y
            path.points.push(x, y)
            return
        }


        for (let target of dragTargets) {
            const delta = {
                x: event.movementX * 1/camera.pos.z,
                y: event.movementY * 1/camera.pos.z
            }
            target.onDragMove && target.onDragMove(target, delta);
        }

        if (selectionRect) {
            selectionRect.x1 = event.data.global.x
            selectionRect.y1 = event.data.global.y
        }
    }

    const drag = (event, target) => {
        dragTargets = selected.length ? selected : ([target] || [event.target]);
        onDragStart(event)
    }

    function onDragStart(event) {
        app.stage.on('pointermove', onDragMove);
        if (mode == modes.DRAW) return

        for (let target of dragTargets) {
            target.onDragStart && target.onDragStart(event);
        }
    }

    function onDragEnd(event) {
        app.stage.off('pointermove', onDragMove);

        if (mode == modes.DRAW && path) {
            if (path.points?.length) {
                const p = Path(path, 3*battle.scale)
                paths.push(p)
                pathsContainer.addChild(p)
                dispatch(NewPathAction(path))
            }
            path = null
            drawing.destroy()
            drawing = null
        }

        if (dragTargets.length) {
            for (let target of dragTargets) {
                target.onDragEnd && target.onDragEnd(target);
            }
            if (selected.length) bridge.save({ blocks: selected })
            dragTargets = [];
        }

        if (selectionRect) {
            const x = (Math.min(selectionRect.x0, selectionRect.x1) - app.screen.width/2)/camera.pos.z - camera.pos.x
            const y = (Math.min(selectionRect.y0, selectionRect.y1) - app.screen.height/2)/camera.pos.z - camera.pos.y
            const w = Math.abs(selectionRect.x1 - selectionRect.x0)/camera.pos.z
            const h = Math.abs(selectionRect.y1 - selectionRect.y0)/camera.pos.z

            if (!isPressed('Shift')) {
                selected.forEach(block => block.onDeselected && block.onDeselected(block))
                selected = []
            }

            const match = blocks.filter(block => {
                return block.x > x && block.x < x + w
                    && block.y > y && block.y < y + h
            })
            match.forEach(block => block.onSelected && block.onSelected(block))
            selected = [...(new Set([...selected, ...match]))]
        }

        selectionRect = null;
        selectionBoxGraphics.clear()
    }

    window.document.addEventListener('wheel', (e) => {
        if (selected.length) {
            selected.forEach(block => block.rotation += (10 * (e.deltaY > 0 ? 1 : -1))/2*Math.PI/180)
            bridge.save({ blocks: selected })
            return
        }

        if (e.deltaY < 0) camera.zoom(-0.02)
        else camera.zoom(0.02)
        worldContainer.scale.set(camera.pos.z);
    })

    app.stage.addChildAt(battlefield.container, 0);
    app.stage.addChild(worldContainer);
    app.stage.addChild(selectionBoxGraphics);

    const updateLayersVisibility = () => {
        pathsContainer.visible = layerMask & PATHS_LAYER
        blocksContainer.visible = layerMask & BLOCKS_LAYER
    }

    app.ticker.add(() => {
        worldContainer.x = (camera.pos.x + app.screen.width/2/camera.pos.z) * camera.pos.z
        worldContainer.y = (camera.pos.y + app.screen.height/2/camera.pos.z) * camera.pos.z

        battlefield.render(camera)

        if (selectionRect?.x1) {
            const x = Math.min(selectionRect.x0, selectionRect.x1)
            const y = Math.min(selectionRect.y0, selectionRect.y1)

            selectionBoxGraphics.clear()
            selectionBoxGraphics.lineStyle(3, 0xFFFFFF);
            selectionBoxGraphics.rect(
                x,
                y,
                Math.abs(selectionRect.x1 - selectionRect.x0),
                Math.abs(selectionRect.y1 - selectionRect.y0)
            );
            selectionBoxGraphics.visible = true;
            selectionBoxGraphics.stroke()
        }

        if (path) {
            if (path.points.count < 2) return;
            drawing.moveTo(path.points[0][0], path.points[0][1]);
            // path.points.forEach(([x, y]) => drawing.lineTo(x, y))

            for (let i = 2; i < path.points.length; i+=2) {
                drawing.lineTo(path.points[i], path.points[i+1]);
            }

            drawing.stroke({
                width: 3*battle.scale,
                color: path.color,
                alpha: 1,
                native: true,
                cap: 'round',
                join: 'round',
            });
        }
    })



    // EVENT HANDLERS

    SetMapPinEvent.subscribe(() => {
        dispatch(SetCameraAction(camera.pos))
    })

    UnitVisibilityEvent.subscribe(({ detail }) => {
        Object.entries(detail).forEach(([unit, value]) => {
            if (!blockLayers[unit]) return
            blockLayers[unit].visible = value
        })
    })

    FocusEvent.subscribe(({ detail }) => {
        if (!detail) return
        camera.moveTo({ x: -detail.x, y: -detail.y })
    })

    SelectModeEvent.subscribe(({ detail }) => {
        mode = detail
    })

    SelectColorEvent.subscribe(({ detail }) => {
        console.log(detail)
        detail != color && SetColorEvent.emit(color)
        color = detail
    })

    PathsVisibilityEvent.subscribe(({ detail }) => {
        layerMask = detail ? layerMask | PATHS_LAYER : layerMask & ~PATHS_LAYER
        updateLayersVisibility()
    })

    ZoomEvent.subscribe(({ detail }) => {
        if (detail < 0) camera.zoom(-0.02)
        else camera.zoom(0.02)
        worldContainer.scale.set(camera.pos.z);
    })


    // watch((u) => {
    //     const selectedIds = selected.map(block => block.uuid)
    //     battle = u
    //     // updateBlocks()
    //     updatePaths()
    //     selected = blocks.filter(block => selectedIds.includes(block.uuid))
    //     selected.forEach(block => block.onSelected && block.onSelected(block))
    // })

}
