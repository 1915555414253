console.log('init input')

export const isTouchDevice = () => ('ontouchstart' in window) || !!navigator.maxTouchPoints || !!navigator.msMaxTouchPoints

const keys = {}
const mouse = {
    pointer: { x: 0, y: 0 },
    cursor: { x: 0, y: 0 },
    click: false,
    down: false,
    middleDown: false,
    rigthDown: false,
    clickPoint: { x: 0, y: 0},
    dragg: true,
}

const MOUSE = {
    LEFT: 0x1,
    RIGHT: 0x2,
    CENTER: 0x4,
}

const onkeypressed = []
const onwheel = []
const ondown = []
const onrdown = []
const onclick = []
const onrclick = []
const onmove = []
const ondrag = []
const onrdrag = []
const onmiddledrag = []
const ondoubleclick = []

const handleKeydown = e => {
    keys[e.key] = true
    onkeypressed.forEach(fn => fn(mouse, isPressed, e.key))
}

const handleKeyup = e => keys[e.key] = false

const handleWheel = e => {
    const mul = (e.wheelDelta > 0 ? 1 : -1) * 5
    onwheel.forEach(fn => fn(mul, isPressed))
}

const handleMousedown = e => {
    const now = Date.now()

    switch(e.buttons) {
        case MOUSE.LEFT:
            mouse.dblclick = (now - mouse.click) <= 300
            if (mouse.dblclick) ondoubleclick.forEach(fn => fn(mouse, isPressed))
            mouse.down = true
            mouse.click = now
            mouse.clickPoint = { x: e.clientX || e.touches[0].clientX, y: e.clientY || e.touches[0].clientY }
            ondown.forEach(fn => fn(mouse, isPressed))
            break
        case MOUSE.RIGHT:
            mouse.rigthDown = true
            mouse.clickPoint = { x: e.clientX || e.touches[0].clientX, y: e.clientY || e.touches[0].clientY }
            break
        case MOUSE.CENTER:
            mouse.middleDown = true
            mouse.clickPoint = { x: e.clientX || e.touches[0].clientX, y: e.clientY || e.touches[0].clientY }
            break
    }
}

const handleClick = () => {
    mouse.down && onclick.forEach(fn => fn(mouse, isPressed))
    mouse.rigthDown && onrclick.forEach(fn => fn(mouse, isPressed))
    mouse.down = false
    mouse.middleDown = false
    mouse.rigthDown = false
    mouse.clickPoint = false
    mouse.dblclick = false
}

const handleRightDown = (e) => {
    e.preventDefault();
    onrdown.forEach(fn => fn(mouse, isPressed))
    mouse.rigthDown = true
}

const handleMove = e => {
    const l = { x: mouse.pointer.x, y: mouse.pointer.y }
    mouse.pointer.x = e.clientX
    mouse.pointer.y = e.clientY

    onmove.forEach(fn => fn(mouse, isPressed))

    const distance = Math.sqrt(((mouse.clickPoint.x - mouse.pointer.x) ** 2) + ((mouse.clickPoint.y - mouse.pointer.y) ** 2))
    if (mouse.down && distance >= 5) {
        ondrag.forEach(fn => fn(l, mouse.pointer, isPressed))
        mouse.dragg = true
    } else  {
        mouse.dragg = false
    }
    if (mouse.middleDown && distance >= 5) onmiddledrag.forEach(fn => fn(l, mouse.pointer))
    if (mouse.rigthDown && distance >= 5) onrdrag.forEach(fn => fn(l, mouse.pointer))
}


let touchesDistance = 0
let lastRotation = 0
const handleTouchMove = e => {
    const l = { x: mouse.pointer.x, y: mouse.pointer.y }
    mouse.pointer.x = e.touches[0].clientX
    mouse.pointer.y = e.touches[0].clientY

    if (!e.touches?.length) return

    if (e.touches.length == 2) {
        var dx = e.touches[1].clientX - e.touches[0].clientX;
        var dy = e.touches[1].clientY - e.touches[0].clientY;

        const distance = Math.sqrt(dx * dx + dy * dy);
        const rotation = Math.atan2(dy, dx);

        const degrees = ((rotation * (180 / Math.PI) * -1) + 180) % 360
        if (lastRotation == 0) lastRotation = degrees
        if (distance > 100 && degrees != lastRotation) {
            console.log(degrees, lastRotation)
            // alert(degrees)
            onwheel.forEach(fn => fn(degrees - lastRotation, isPressed))
            lastRotation = degrees
            return
        }

        onmiddledrag.forEach(fn => fn(l, mouse.pointer))
    } else {
        ondrag.forEach(fn => fn(l, mouse.pointer, isPressed))
        mouse.dragg = true
    }
}

const handleTouchStart = e => {
    mouse.pointer = { x: e.touches[0].clientX, y: e.touches[0].clientY }
    mouse.clickPoint = { x: e.touches[0].clientX, y: e.touches[0].clientY }
    mouse.down = true
    onmove.forEach(fn => fn(mouse, isPressed))
    ondown.forEach(fn => fn(mouse, isPressed))
}

const handleTouchEnd = e => {
    mouse.down && onclick.forEach(fn => fn(mouse, isPressed))
    mouse.down = false
    mouse.dragg = false
    lastRotation = 0
}


export const bind = () => {
    window.addEventListener('keydown', handleKeydown)
    window.addEventListener('keyup', handleKeyup)

    const canvas = document.querySelector('canvas')

    canvas.addEventListener('touchmove', handleTouchMove)
    canvas.addEventListener('touchstart', handleTouchStart)
    canvas.addEventListener('touchend', handleTouchEnd)

    canvas.addEventListener('wheel', handleWheel)
    canvas.addEventListener('mousedown', handleMousedown)
    canvas.addEventListener('mouseup', handleClick)
    canvas.addEventListener('contextmenu', handleRightDown)
    canvas.addEventListener('mousemove', handleMove)
}

export const onKeyPressed = (fn) => onkeypressed.push(fn)
export const onWheel = (fn) => onwheel.push(fn)
export const onMouseDown = (fn) => ondown.push(fn)
export const onMouseRightDown = (fn) => onrdown.push(fn)
export const onMouseClick = (fn) => onclick.push(fn)
export const onMouseRightClick = (fn) => onrclick.push(fn)
export const onMouseMove = (fn) => onmove.push(fn)
export const onMouseDrag = (fn) => ondrag.push(fn)
export const onMouseMiddleDrag = (fn) => onmiddledrag.push(fn)
export const onMouseRightDrag = (fn) => onrdrag.push(fn)
export const onDoubleClick = (fn) => ondoubleclick.push(fn)
export const isPressed = k => keys[k]

export {
    keys,
    mouse,
}
