import { Outlet } from "react-router";

import { Panel } from '../../components/panel';
import { Container } from '../../components/container';
import { Box } from '../../components/box';
import logo from './logo.png';

export const Base = () => {
    return (
        <Container>
            <Panel>
                <Box gap='2em'>
                    <img src={logo} alt="logo" />
                    <Outlet />
                </Box>
            </Panel>
        </Container>
    )
}
