import React from 'react'
import styled from 'styled-components'

export const Select = styled.select`
    box-sizing: border-box;
    border-radius: 5px;
    padding: .7em;
    width: 100%;
    background: white;
    border: 1px solid ${ props => props.theme.colors.black };
    color: ${ props => props.theme.colors.black };
    cursor: pointer;
    width: 100%;
`
