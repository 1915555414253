import React from 'react'
import styled from 'styled-components'

export const ProgressBar = styled.div`
border: 2px solid black; 
border-radius: 5px;
position: relative;
height: 1.4em;
width: 100%;
box-sizing: border-box;

&::before {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  bottom: 2px;
  width: ${props => props.progress * 100}%;
  background-color: ${ props => props.theme.colors.main };
  border-radius: 5px;
}

&::after {
  content: '${props => (props.progress * 100)|0 }%';
  color: white;
  font-weight: bold;
  margin: 0;
  padding: 0;
  position: absolute;
  top: .1em;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 1em;
}
`
