import React from 'react';
import { Box } from './box';

export const Footer = () => {
    return (
        <Box row style={{ justifyContent: 'center' }}>
            <a href="/doc/" target="blank">Guide</a>
            <span>|</span>
            <span>
                Join our <a href="https://discord.gg/SvdAuCZsVC" target="discord">discord</a> community for updates and new beta phases
            </span>
        </Box>
    )
}
