import React from 'react'
import styled from 'styled-components'
import { ClickableIcon, CloseIcon } from './icons'
import { Form } from './form'
import { Box } from './box'
import { Button } from './button'

const ModalContainer = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 8;
    top: 0;
    left: 0;

    display: flex;
    justify-content: center;

    background: rgba(0,0,0,.5);
`

const ModalContent = styled.div`
    position: relative;
    height: fit-content;
    max-height: 80vh;
    max-width: 90%;
    width: fit-content;
    padding: 1em;
    box-sizing: border-box;

    background: white;
    border: 2px solid ${ props => props.theme.colors.secondary };
    border-radius: 5px;

    -webkit-box-shadow: 10px 4px 21px -4px rgba(0,0,0,0.54);
    -moz-box-shadow: 10px 4px 21px -4px rgba(0,0,0,0.54);
    box-shadow: 10px 4px 21px -4px rgba(0,0,0,0.54);

    align-self: center;
    display: flex;
    // justify-content: center;
    flex-direction: column;
    overflow-x: auto;
    overflow-y: scroll;

    // & > * {
    //     padding: 1em;
    // }

    & > div {
        overflow-y: auto;
    }

`

const Close = styled.span`
position: absolute;
padding: 0;
top: -.5em;
left: -.5em;
font-size: 2em;

i {
    color: ${ props => props.theme.colors.main };

    &:hover {
    color: ${ props => props.theme.colors.mainDark };
    }
}
`

const Actions = styled.div`
    display: flex;
    gap: 1em;
    justify-content: center;
    flex-direction: column;

    ${ props => props.theme.desktop(`
        flex-direction: row;
        white-space: nowrap;
        flex-wrap: nowrap;
    `) }

    padding: 0;
    margin: 1em 0 0 0;
`

export const Modal = ({
    open,
    children,
    disabled,
    disabledAll,
    onSubmit,
    onClose,
    onCloseText,
}) => {
    if (!open) return null
    return <ModalContainer>
        <ModalContent>
            <Form onSubmit={ onSubmit }>
                <Box>
                    { children }
                </Box>
                <Actions style={{ overflow: 'initial' }}>
                    { onClose && <Button disabled={ disabled && disabledAll } secondary onClick={ onClose }>{ onCloseText || 'Back' }</Button> }
                    { onSubmit && <Button disabled={ disabled } type="submit">Submit</Button> }
                </Actions>
            </Form>
        </ModalContent>
    </ModalContainer>
}
