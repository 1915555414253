import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from 'styled-components';

import theme from './theme';

import {
    BrowserRouter,
    Routes,
    Route,
    Outlet,
    Navigate,
    useLocation,
    useNavigate,
} from "react-router";

import { UserProvider, useUser } from './context/user'
import { BattlesProvider } from './context/battles'
import { ErrorProvider } from './context/error'

import { Router as LoginRouter } from './pages/login';
import { Router as BattlesRouter } from './pages/battles';
import { Router as GameRouter } from './pages/game';

import styled from 'styled-components';
import './index.css';

import { Profile } from './components/profile';
import { Footer } from './components/footer';
import { Modal } from './components/modal';
import { Box } from './components/box';

const StyledFooter = styled.footer`
  background-color: white;
  padding: .5rem;
  text-align: center;
  justify-content: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 0.8rem;
`;

const FeatureModal = ({ open, onClose }) => {
    return <Modal open={ open } onClose={ onClose } onCloseText='Close'>
        <h3>New features arrived!!</h3>
        <Box>
            <div style={{ maxWidth: '500px' }}>
                <p>The game layer has been completely rewritten using Pixi.js to foster stability and user experience.</p>
                <p>intuitive controls has been implemented:</p>
                <ul> <b>blocks</b>
                    <li>Left click to select</li>
                    <li>Drag with right mouse button down to select in area</li>
                    <li>Hold Shift while selecting to add to current selection</li>
                    <li>Click anywhere to clear selection</li>
                    <li>Shift + left click to move selected units</li>
                    <li>Drag selected blocks to move them</li>
                    <li>Use the mouse wheel to rotate the selected blocks</li>
                </ul>

                <ul> <b>camera</b>
                    <li>Drag to pan the camera</li>
                    <li>Use the mouse wheel to zoom in/out</li>
                </ul>

                <ul> <b>drawings</b>
                    <li>Hold left click and move to draw</li>
                    <li>Right click in a drawing to delete it</li>
                </ul>

                Game controls has always been a pain point in the game, so we hope this new controls will make the sessions more enjoyable.
                Also, the old game architecture was a mess and a roadblock for multiplayer integration, so I hope this new one will clear the way and be more stable and less buggy.

                <p>Thanks for your support and feedback, have fun!</p>
            </div>
        </Box>
    </Modal>
}

const FEATURE_FLAG = 'FEATURE_MODAL_PIXI'

const Base = ({ children }) => {
    const [featureModalOpen, setFeatureModalOpen] = React.useState(localStorage.getItem(FEATURE_FLAG) !== 'true')

    const onCloseFeatureModal = () => {
        setFeatureModalOpen(false)
        localStorage.setItem(FEATURE_FLAG, true)
    }

    return (
        <ErrorProvider>
            <Profile/>
            { children }

            <FeatureModal open={ featureModalOpen } onClose={ onCloseFeatureModal }/>
            <StyledFooter>
                <Footer />
            </StyledFooter>
        </ErrorProvider>
    )
};

const Protected = ({ children }) => {
    const { user } = useUser()
    const location = useLocation()

    if (!user) return <Navigate to={ `/auth?from=${ location.pathname }` } />
        return <Outlet />
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ThemeProvider theme={theme}>
        <BrowserRouter>
            <UserProvider>
                <Base>
                    <Routes>
                        <Route path="/auth/*" element={<LoginRouter />} />
                        <Route path="/*" element={ <Protected/> }>
                            <Route path="game/*" element={<GameRouter />} />
                            <Route path="*" element={<BattlesRouter />} />
                        </Route>
                    </Routes>
                </Base>
            </UserProvider>
        </BrowserRouter>
    </ThemeProvider>
);
