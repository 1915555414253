import React from 'react'
import styled from 'styled-components'

import { Modal } from '../../../components/modal'
import { Button } from '../../../components/button'
import { Toolbar } from './toolbar'
import { screenshot, exportJSON } from '../../../utils'

import {
    useBattle,
    NewBlockAction,
    DeleteBlockAction,

    SetBattleConfigAction,
    ClearBlocksAction,
    ClearPathsAction,
} from '../../../game/stores/battle'

import { BugReportModal } from './modals/bug'
import { BlocksPanel } from './panels/blocks'
import { ConfigPanel } from './panels/config'

const StyledUI = styled.div`
position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 2em;
pointer-events: none;

* {
    pointer-events: auto;
}
`

const MODAL = {
    NONE: 0,
    BUG_REPORT: 1,
    BLOCKS: 2,
    CONFIG: 3,
}

export const UI = ({ canvas, onExit }) => {
    const { battle, dispatch } = useBattle()
    const [modal, setModal] = React.useState(MODAL.NONE)

    const handleToggleModal = (m) => setModal(s => s === m ? MODAL.NONE : m)


   const handleCreateBlock = block => dispatch(NewBlockAction(block))
   const handleDeleteBlock = blockid => dispatch(DeleteBlockAction(blockid))

    return (
        <StyledUI>
            <Toolbar
                battle={ battle }

                onShowHierarchy={ () => handleToggleModal(MODAL.BLOCKS) }
                hierarchyOpen={ modal == MODAL.BLOCKS }

                onShowConfig={ v => handleToggleModal(MODAL.CONFIG, v) }
                configOpen={ modal == MODAL.CONFIG }

                onShowBugForm={ v => handleToggleModal(MODAL.BUG_REPORT, v) }
                onScreenshot={ () => screenshot(canvas, `${battle.name}.${battle.id}.screenshot`) }
                onExport={ () => exportJSON(battle, `${battle.name}.${battle.id}.battle`) }
                onExit={ onExit }
            />

            <BugReportModal
                battle={ battle }
                open={ modal == MODAL.BUG_REPORT }
                onClose={ v => handleToggleModal(MODAL.BUG_REPORT, v) }
            />

            <BlocksPanel
                open={ modal == MODAL.BLOCKS }
                blocks={ battle.blocks }
                onCreate={ handleCreateBlock }
                onDelete={ handleDeleteBlock }
            />

            <ConfigPanel
                open={ modal == MODAL.CONFIG }
                battle={ battle }
                onChange={ (config) => dispatch(SetBattleConfigAction(config)) }
                onClearBlocks={ () => dispatch(ClearBlocksAction())}
                onClearPaths={ () => dispatch(ClearPathsAction())}
            />
        </StyledUI>
    )
}
